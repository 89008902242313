import { Controller } from 'stimulus';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import nlLocale from '@fullcalendar/core/locales/nl';
export default class extends Controller {
  static targets = ['cal', 'settings', 'customTime', 'date', 'timeStart', 'timeEnd', 'btnShow', 'btnHide', 'criteria'];

  connect() {
    let settings = JSON.parse(this.data.get('settings'));
    this.calendar = new Calendar(this.calTarget, {
      plugins: [ timeGridPlugin, interactionPlugin ],
      now: settings.now,
      locale: nlLocale,
      editable: false,
      selectable: true,
      selectOverlap: false,
      selectAllow: function(selectionInfo) {
        let startDate = selectionInfo.start;
        let endDate = selectionInfo.end;
        endDate.setSeconds(endDate.getSeconds() - 1);  // allow full day selection
        if (startDate.getDate() === endDate.getDate()) {
            return true;
        } else {
            return false;
        }
      },
      allDaySlot: false,
      aspectRatio: 1.8,
      eventMaxStack: 1, // This is the option that bundles everything in just a few blocks, nice!
      scrollTime: '00:00',
      slotMinTime: settings.dayTimeStart,
      slotMaxTime: settings.dayTimeEnd,
      headerToolbar: {
        left: 'today',
        center: 'title',
        right: 'prev,next'
      },
      initialView: 'timeGridWeek',
      views: {
      },
      events:
        {
          url: settings.url,
          beforeSend: function( xhr ) {
            // should try to abort previous xhr request
            // for performance reasons
            // 'beforeSend' does not seem an option..
          }
        },
      dateClick: function(info) {
        // alert('clicked ' + info.dateStr);
      },
      select: function(info) {
        this.customTimeController.show('custom');

        let date = this.getDateString(info.start);
        this.dateTarget.value = date;
        this.datePickerController.updateValue(date);

        let start = this.getTimeString(info.start)
        this.timeStartTarget.value = start;

        let end = this.getTimeString(info.end)
        this.timeEndTarget.value = end;

        this.getCriteriaController.updateWithoutNumberOfPeople();
      }.bind(this),
      loading: function( isLoading, view ) {
        if(isLoading) {// isLoading gives boolean value
            //show your loader here
            this.element.classList.add('loading');
        } else {
            //hide your loader here
            this.element.classList.remove('loading');
        }
      }.bind(this)
    });

    this.calendar.render();
  }

  getDateString(d) {
    return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +  d.getFullYear();
  }

  getTimeString(d) {
    return ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
  }

  get customTimeController() {
    return this.application.getControllerForElementAndIdentifier(this.customTimeTarget, "custom-time")
  }

  get datePickerController() {
    return this.application.getControllerForElementAndIdentifier(this.dateTarget, "datepicker")
  }

  get getCriteriaController() {
    return this.application.getControllerForElementAndIdentifier(this.criteriaTarget, "criteria")
  }

  show(e){
    e.preventDefault();
    this.calTarget.style.display = 'block';
    this.btnShowTarget.style.display = 'none';
    this.calendar.updateSize();
  }

  hide(e){
    e.preventDefault();
    this.calTarget.style.display = 'none';
    this.btnShowTarget.style.display = 'inline-block';
  }

  setDate(e) {
    let date = Date.parse(this.dateTarget.value);
    this.calendar.gotoDate(date);
  }
}




