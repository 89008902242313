// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');

import '@stimulus/polyfills';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import 'selectize/dist/js/selectize.js';
import flatpickr from 'flatpickr';

const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Make file upload work (so the name of the file appears)
document.addEventListener('turbolinks:load', function (event) {
  var selector = '.custom-file-input';
  if (document.querySelector(selector) !== null) {
    document.querySelectorAll(selector).forEach(function (input) {
      input.addEventListener('change', function (e) {
        var fileName = '';
        for (var i = 0; i < this.files.length; i++) {
          fileName += this.files[i].name;
          if (i < this.files.length - 1) {
            fileName += ', ';
          }
        }
        var nextSibling = e.target.nextElementSibling;
        nextSibling.innerText = fileName;
      });
    });
  }

  // Analytics
  if (typeof gtag !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'anonymize_ip': true,
    'page_location': event.data.url
    });
  }
});
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
