import { Controller } from 'stimulus';
import { Dutch } from "flatpickr/dist/l10n/nl.js"

export default class extends Controller {
  connect() {
    this.picker = flatpickr(this.element, {
      allowInput: true,
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: true,
      locale: Dutch
    });
  }

  disconnect() {
    this.picker.destroy();
  }
}
