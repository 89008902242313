export function saveToUserSettings(key, value) {
  var user_settings = {};
  user_settings[key] = value;

  fetch(window.user_settings_ajax_url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({ user_settings }),
  });
}
