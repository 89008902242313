import { tns } from 'tiny-slider/src/tiny-slider';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['slides'];
  initialize() {}

  connect() {
    this.slides = tns(this.settings);
  }

  disconnect() {
    this.slides.destroy();
  }

  get settings() {
    const settings = JSON.parse(this.data.get('settings'));
    settings.container = this.slidesTarget;
    return settings;
  }
}
