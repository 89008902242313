import { Controller } from 'stimulus';
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect.js';
import { Dutch } from 'flatpickr/dist/l10n/nl.js';

export default class extends Controller {
  connect() {
    this.altFormat = 'd-m-Y';

    let options = {
      dateFormat: 'Y-m-d',
      allowInput: true,
      altFormat: this.altFormat,
      altInput: true,
      locale: Dutch,
    };

    if (this.data.get('weekselect') === 'true') {
      options = {
        plugins: [new weekSelect({})],
        allowInput: true,
        altFormat: this.altFormat,
        altInput: true,
        locale: Dutch,
      };
    }

    this.picker = flatpickr(this.element, options);

    // Make sure the original input is emptied when the alt input is empty
    this.emptyValueWhenAltInputIsEmpty();

    // Make sure that on close of the alt input any manual typed changes flow back to the input field
    this.updateAsYouWrite();
  }

  emptyValueWhenAltInputIsEmpty() {
    this.altInput = this.picker.altInput;
    this.input = this.picker.input;

    this.altInput.addEventListener('input', (e) => this.listenForEmpty(e));
  }

  listenForEmpty(e) {
    if (e.target.value == '' || typeof e.target === 'undefined') {
      this.input.value = '';
    }
  }

  updateAsYouWrite() {
    this.altInput.addEventListener('input', (e) => this.listenForWrites(e));
  }

  listenForWrites() {
    const value = this.picker.altInput.value;
    if (value != '') {
      const parsedDate = this.picker.parseDate(value, this.altFormat);
      const formattedDate = this.picker.formatDate(parsedDate, this.altFormat);
      if (value === formattedDate) {
        this.picker.setDate(value, true, this.altFormat);
      }
    }
  }

  disconnect() {
    this.altInput.removeEventListener('input', this.listenForEmpty);
    this.altInput.removeEventListener('input', this.listenForWrites);
    this.picker.destroy();
  }

  updateValue(value){
    this.picker.setDate(value, false, this.altFormat);
  }
}
