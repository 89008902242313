import { Controller } from 'stimulus';
import { saveToUserSettings } from 'helpers/user_settings';

export default class extends Controller {
  static targets = ['trigger', 'content'];

  connect() {
    this.toggleClass = this.data.get('class');
  }

  toggle() {
    this.is_active = this.element.classList.toggle(this.toggleClass);

    let user_setting = this.data.get('user-setting');

    if (user_setting) {
      saveToUserSettings(user_setting, this.is_active);
    }
  }

  active() {
    this.element.classList.add(this.toggleClass);
  }

  inactive() {
    this.element.classList.remove(this.toggleClass);
  }
}
