import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['term', 'button'];

  connect() {
    this.checkAcceptance();
  }

  // all terms should be checked
  checkAcceptance(e) {
    for (let i = 0; i < this.termTargets.length; i++) {
      if(this.termTargets[i].checked == false){
        this.disableButton();
        return false;
      }
    }
    this.enableButton();
  }

  enableButton() {
    this.buttonTarget.classList.remove('disabled');
  }

  disableButton() {
    this.buttonTarget.classList.add('disabled');
  }
}
