import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['kind'];

  connect() {
    this.showFieldsForKind();
  }

  showFieldsForKind() {
    const selectedKind = this.selectedKind();
    const arrangementFields = [
      'min_number_of_people',
      'max_number_of_people',
      'daypart_amount',
      'price_per_arrangement',
    ];
    const singlePriceFields = [
      'category',
      'part_of_room_rental',
      'mandatory_daypart_ids',
      'discounts_applicable',
      'vat_category_id',
      'deductable',
      'price_per_single',
    ];

    switch (selectedKind) {
      case 'arrangement':
        arrangementFields.map((name) => this.showField(name));
        singlePriceFields.map((name) => this.hideField(name));
        this.showSinglePrices();
        this.hideExtraField();
        this.showArrangementMessage();
        break;
      case 'single_price':
        arrangementFields.map((name) => this.hideField(name));
        singlePriceFields.map((name) => this.showField(name));
        this.hideSinglePrices();
        this.showExtraField();
        this.hideArrangementMessage();
        break;
    }
  }

  selectedKind() {
    for (var i = 0; i < this.kindTargets.length; i++) {
      if (this.kindTargets[i].checked) {
        return this.kindTargets[i].value;
      }
    }
  }

  showField(fieldName) {
    var field = document.getElementById('price_' + fieldName);
    field.removeAttribute('disabled');
    jQuery(field).parents('.form-group')[0].style.display = 'flex';
  }

  hideField(fieldName) {
    var field = document.getElementById('price_' + fieldName);
    field.setAttribute('disabled', 'disabled');
    jQuery(field).parents('.form-group')[0].style.display = 'none';
  }

  setSinglePricesDisplay(cssDisplayValue) {
    const section = document.getElementById('single-prices-section');
    if (!section) return;
    // Compensate for distance between "cards" because we want it to conditionally
    // look like elements are in the same "card" when they're really not.
    section.style.marginTop = cssDisplayValue === 'none' ? '-66px' : '0px';

    const [sectionCard] = section.children;
    if (!sectionCard) return;

    const [cardHeader, cardBody] = sectionCard.children;
    if (cardHeader) cardHeader.style.display = cssDisplayValue;
    // Assume price, price_per, vat percentage, vat deductable,
    // discounts_applicable, price_per_single and part_of_room_rental are still the first 6
    // children (and arranged by other functions), show/hide everything else.
    if (cardBody)
      for (let i = 7; i < cardBody.children.length; i++)
        cardBody.children[i].style.display = cssDisplayValue;
  }

  showSinglePrices() {
    this.setSinglePricesDisplay('block');
  }

  hideSinglePrices() {
    this.setSinglePricesDisplay('none');
    var section = document.getElementById('single-prices-section');
    if (section != null) {
      for (var i = 0; i < section.getElementsByTagName('input').length; i++) {
        var el = section.getElementsByTagName('input')[i];
        el.removeAttribute('checked');
      }
    }
  }

  showExtraField() {
    var formGroup = document.getElementById('extra-field');
    if (formGroup != null) {
      formGroup.style.display = 'block';
    }
  }

  hideExtraField() {
    var formGroup = document.getElementById('extra-field');
    if (formGroup != null) {
      formGroup.style.display = 'none';
    }
  }

  showArrangementMessage() {
    var formGroup = document.getElementById('arrangement-message');
    if (formGroup != null) {
      formGroup.style.display = 'block';
    }
  }

  hideArrangementMessage() {
    var formGroup = document.getElementById('arrangement-message');
    if (formGroup != null) {
      formGroup.style.display = 'none';
    }
  }
}
