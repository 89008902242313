import React from "react";
import PropTypes from "prop-types";

import { AvailableProductPropType, TranslationsProptype } from "./shared-types";

const ArrangementProduct = (props) => {
  const { id, category, price, startDate, endDate, arrangementStartDate, arrangementEndDate, productsInCategory, firstUnusedProductByCategory, setProduct, removeProduct, t, vatPercentage } = props;

  const categoryOptions = Object.entries(firstUnusedProductByCategory).filter(
    ([cat, firstUnusedProduct]) => cat === category || firstUnusedProduct
  ).map(([cat, firstUnusedProduct]) => [cat, cat === category ? id : firstUnusedProduct.id]);
  const options = productsInCategory.filter((product) => product.id === id || !product.used);

  const validationErrors = [];
  if (startDate && (!arrangementStartDate || startDate > arrangementStartDate)) validationErrors.push(
    t.validationMessages.arrangement_starts_before_component.replace(":date", startDate)
  );
  if (endDate && (!arrangementEndDate || endDate < arrangementEndDate)) validationErrors.push(
    t.validationMessages.arrangement_ends_after_component.replace(":date", endDate)
  );
  const tdOptions = validationErrors.length ? { style: { paddingBottom: 0 } } : {};

  const setPriceFromEvent = (e) => setProduct(id, Number(e.target.value));
  const setIdFromEvent = (e) => setProduct(Number(e.target.value), null);

  return <>
    <tr style={ validationErrors.length ? { borderBottom: "none" } : null }>
      <td { ...tdOptions }>
        <div className="form-control fill-parent">
          <select
            value={ id }
            onChange={ (e) => setIdFromEvent(e) }
          >
            { categoryOptions.map(
              ([category, productId]) => <option key={ productId } value={ productId }>{ t.categories[category] }</option>
            ) }
          </select>
        </div>
      </td>
      <td { ...tdOptions }>
        <div className="form-control fill-parent">
          <select
            name={ `price[inverse_arrangements_prices_attributes][][price_id]` }
            value={ id }
            onChange={ (e) => setIdFromEvent(e) }
          >
            { options.map(
              (product) => <option key={ product.id } value={ product.id }>
                { product.name } (&euro;{ product.price } per { t.pricePerOptions[product.price_per].toLowerCase() })
              </option>
            ) }
          </select>
        </div>
      </td><td { ...tdOptions }>
        <input
          type="number"
          name={ `price[inverse_arrangements_prices_attributes][][custom_price]` }
          value={ price === 0 ? "" : price }
          onChange={ (e) => setPriceFromEvent(e) }
          className="form-control"
        />
      </td><td { ...tdOptions }>
        { vatPercentage }
      </td><td { ...tdOptions }>
        <button
          type="button"
          onClick={ removeProduct }
          className="btn-delete-row"
        >
          <i className="mdi mdi-delete mdi-18px"></i>
        </button>
      </td>
    </tr>
    { validationErrors.length !== 0 && <tr style={{ borderTop: "none" }}>
      <td colSpan="5" style={{ paddingTop: 0 }}>
        { validationErrors.map((error) => <p key={ error } style={{ marginBottom: 0 }}>
          <small className="form-text" style={{ color: "red" }}>{ error }</small>
        </p>) }
      </td>
    </tr> }
  </>
};

ArrangementProduct.propTypes = {
  id: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  price: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  arrangementStartDate: PropTypes.string,
  arrangementEndDate: PropTypes.string,
  productsInCategory: PropTypes.arrayOf(AvailableProductPropType),
  firstUnusedProductByCategory: PropTypes.objectOf(AvailableProductPropType),
  setProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  t: TranslationsProptype.isRequired,
  vatPercentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ArrangementProduct;
