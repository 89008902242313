import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['source', 'destination'];

  connect() {
    this.copy();
  }

  copy() {
    let value = this.sourceTarget.value;
    if (value.length == 0 && this.data.get('blank').length > 0) {
      value = this.data.get('blank');
    }
    this.destinationTarget.innerHTML = value;
  }
}
