import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['numberOfPeople', 'setup', 'date', 'timeStart', 'timeEnd',
    'customTimeStart', 'customTimeEnd', 'customTime', 'submit',
    'dateWarning', 'setupWarning', 'numberOfPeopleLabel'];

  connect() {
    if(this.dateTarget.value != null) {
      this.update();
    }
  }

  update(with_number_of_people=true) {
    let url = this.data.get('url');
    let activeSetup = this.data.get('active-setup');
    let custom = this.customTimeTarget.getAttribute('data-custom-time-selected') == 'custom'
    let data = {
      'date': this.dateTarget.value,
      'timeStart': custom ? this.customTimeStartTarget.value : this.timeStartTarget.value,
      'timeEnd': custom ? this.customTimeEndTarget.value : this.timeEndTarget.value,
      'customTime': this.customTimeTarget.getAttribute('data-custom-time-selected') == 'custom'
    }

    if(with_number_of_people) {
      data['numberOfPeople'] = this.numberOfPeopleTarget.value;
    } else {
      this.numberOfPeopleTarget.value = null;
    }

    if(typeof window['last_xhr'] != 'undefined') {
      window['last_xhr'].abort();
    }

    let jqxhr = jQuery.get(url, data, function(data) {
      this.updateNumberOfPeople(data.number_of_people.min, data.number_of_people.max);
      this.updateSetup(data.setups, activeSetup);
      this.updateSubmit(data.number_of_people.min, data.number_of_people.max, data.setups);
      this.updateDateWarning(data.number_of_people.min, data.number_of_people.max, data.setups);
    }.bind(this));

    window['last_xhr'] = jqxhr;
  }

  updateWithoutNumberOfPeople() {
    this.update(false);
  }

  updateNumberOfPeople(min, max) {
    this.numberOfPeopleTarget.setAttribute('min', min);
    this.numberOfPeopleTarget.setAttribute('max', max);

    if (min > 0) {
      this.numberOfPeopleLabelTarget.innerHTML = '(' + min + ' tot ' + max + ')';
    } else {
      this.numberOfPeopleLabelTarget.innerHTML = '(' + 'wijzig data' + ')'
    }
  }

  updateSetup(setups, activeSetup) {
    try {
      jQuery(this.setupTarget)[0].selectize.destroy();
    } catch(error) {
      // don't do anything
    }

    this.setupTarget.innerHTML = '';
    for(let i=0; i<setups.length; i++) {
      let active = ''
      if(setups[i].value == activeSetup) {
        active = 'selected="selected"'
      }
      this.setupTarget.innerHTML += '<option value="'+setups[i].value+'" '+active+'>'+setups[i].label+'</option>'
    }

    jQuery(this.setupTarget).selectize();
  }

  updateSubmit(min, max, setups) {
    if( (min == null && max == null) || setups.length == 0 ) {
      this.submitTarget.setAttribute('disabled', 'disabled');
    } else {
      this.submitTarget.removeAttribute('disabled', 'disabled');
    }
  }

  updateDateWarning(min, max, setups) {
    if( min == null && max == null && setups.length == 0 ) {
      this.dateWarningTarget.style.display = 'block';
    } else {
      this.dateWarningTarget.style.display = 'none';
    }
  }
}
