import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.openOnError();
  }

  openOnError() {
    this.error_fields = JSON.parse(this.data.get('errorFields'));

    // find error field and open the corresponding toggle element
    for (let i = 0; i < this.error_fields.length; i++) {
      let name = this.error_fields[i];

      let el = document.querySelector('[name="booking[' + name + ']"]');
      if (el != null) {
        // open elements from our custom toggle_controller
        let card = el.closest('.o-toggle');
        if (card != null) {
          card.classList.add('o-toggle--active');
        }

        // open elements from bootstrap toggle controller
        let collapse = el.closest('.collapse');
        if (collapse != null) {
          collapse.classList.add('show');
        }
      }
    }
  }
}
