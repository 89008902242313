import PropTypes from "prop-types";

export const AvailableProductPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  used: PropTypes.bool,
});

export const TranslationsProptype = PropTypes.shape({
  addSinglePrice: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categories: PropTypes.objectOf(PropTypes.string).isRequired,
  componentsWithVatExplanation: PropTypes.string.isRequired,
  currencyUnit: PropTypes.string.isRequired,
  priceInArrangement: PropTypes.string.isRequired,
  pricePerOptions: PropTypes.objectOf(PropTypes.string).isRequired,
  roomRentalIncluded: PropTypes.string.isRequired,
  singlePrice: PropTypes.string.isRequired,
  taxRatePercentage: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  validationMessages: PropTypes.shape({
    arrangement_ends_after_component: PropTypes.string.isRequired,
    arrangement_starts_before_component: PropTypes.string.isRequired,
    no_components: PropTypes.string.isRequired,
    total_price: PropTypes.string.isRequired,
  }).isRequired,
  yes: PropTypes.string.isRequired,
});

export const UsedProductsProptype = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    price: PropTypes.number,
  }),
);
