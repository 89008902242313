import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let options = this.data.get('options');
    if (options != null) {
      options = JSON.parse(options);

      options.onType = function () {
        if (this.$control_input.val().length < 2) {
          this.close();
        }
      };
    }

    this.selectize = jQuery(this.element).selectize(options);
  }

  disconnect() {
    jQuery(this.element)[0].selectize.destroy();
  }
}
