import React, { useState } from "react";
import PropTypes from "prop-types";

import { AvailableProductPropType, TranslationsProptype, UsedProductsProptype } from "./arrangement-products-subcomponents/shared-types";
import ArrangementProductsTable from "./arrangement-products-subcomponents/ArrangementProductsTable";

const mapNanToZero = (x) => isNaN(x) ? 0 : x;

const ArrangementProducts = (props) => {
  const { allProducts, initialUsedProducts, translations, vatCategories } = props;
  const productsById = Object.fromEntries(allProducts.map((product) => [product.id, product]));
  const totalPriceExcludingRoom = initialUsedProducts.map(
    (product) => product.price ?? productsById[product.id].price
  ).reduce((sum, term) => sum + term, 0);
  const [roomRentalPrice, setRoomRentalPrice] = useState(
    Math.max(0, mapNanToZero(Number(document.getElementById("price").value) - totalPriceExcludingRoom))
  );
  const [roomRentIncluded, setRoomRentIncluded] = useState(roomRentalPrice >= 0.01);

  return <>
    <div className="form-group row">
      <label className="col-form-label col-sm-2">{ translations.roomRentalIncluded }</label>
      <div className="col-sm-10 pt-2">
        <div className="form-check">
          <input
            type="checkbox"
            id="room_rent_included"
            className="form-check-input"
            checked={ roomRentIncluded }
            onChange={ (e) => setRoomRentIncluded(e.target.checked) }
          />
          <label className="form-check-label" htmlFor="room-rent-included">{ translations.yes }</label>
        </div>
      </div>
    </div>
    <div className="form-group row">
      <div className="col-sm-2" />
      <div className="col-sm-10">
        <small className="form-text text-muted">{ translations.componentsWithVatExplanation }</small>
      </div>
    </div>
    <div className="form-group row">
      <div className="col-sm-2" />
      <div className="col-sm-10">
        <ArrangementProductsTable
          allProducts={ allProducts }
          initialUsedProducts={ initialUsedProducts }
          translations={ translations }
          roomRentalPrice={ roomRentIncluded ? roomRentalPrice : null }
          setRoomRentalPrice={ setRoomRentalPrice }
          vatCategories={ vatCategories }
        />
      </div>
    </div>
  </>;
};

ArrangementProducts.propTypes = {
  allProducts: PropTypes.arrayOf(AvailableProductPropType).isRequired,
  initialUsedProducts: UsedProductsProptype,
  translations: TranslationsProptype.isRequired,
  vatCategories: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

ArrangementProducts.defaultProps = { initialUsedProducts: [] };

export default ArrangementProducts;
