import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'daypartsContainer',
    'customContainer',
    'customTimeTrue',
    'customTimeFalse',
  ];

  connect() {
    let selected = this.data.get('selected');
    this.show(selected);
  }

  show(which = 'dayparts') {
    if ( which == 'dayparts' ) {
      this.showDayparts(null)
    } else {
      this.showCustom(null);
    }
  }

  showDayparts(e) {
    if ( e != null ) {
      e.preventDefault();
    }
    this.daypartsContainerTarget.style.display = 'block';
    this.customContainerTarget.style.display = 'none';

    for (let i = 0; i < this.customTimeTrueTargets.length; i++) {
      this.customTimeTrueTargets[i].setAttribute('disabled', true);
    }

    for (let i = 0; i < this.customTimeFalseTargets.length; i++) {
      this.customTimeFalseTargets[i].removeAttribute('disabled');
    }
    this.data.set('selected', 'dayparts');
  }

  showCustom(e) {
    if ( e != null ) {
      e.preventDefault();
    }
    this.daypartsContainerTarget.style.display = 'none';
    this.customContainerTarget.style.display = 'block';

    for (let i = 0; i < this.customTimeTrueTargets.length; i++) {
      this.customTimeTrueTargets[i].removeAttribute('disabled');
    }

    for (let i = 0; i < this.customTimeFalseTargets.length; i++) {
      this.customTimeFalseTargets[i].setAttribute('disabled', true);
    }
    this.data.set('selected', 'custom');
  }

}
