import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'arrangement',
    'checkbox',
    'singlePricesContainer',
    'singlePrice',
    'extraField',
    'inventoryPriceType'
  ];

  connect() {
    this.updateView();
  }

  disconnect() {}

  updateView() {
    let selected = this.data.get('selected');
    let selectedSinglePrices = this.data.get('selectedSinglePrices');
    if (selectedSinglePrices != null) {
      selectedSinglePrices = JSON.parse(selectedSinglePrices);
    } else {
      selectedSinglePrices = [];
    }

    if (this.arrangementTargets.length > 0) {
      let arrangement = null;
      for (let i = 0; i < this.arrangementTargets.length; i++) {
        arrangement = this.arrangementTargets[i];
        if (
          parseInt(arrangement.getAttribute('data-index')) == parseInt(selected)
        ) {
          arrangement.classList.add('card-select');
        } else {
          arrangement.classList.remove('card-select');
        }
      }
    }

    if (this.inventoryPriceTypeTargets.length > 0) {
      let inventory_price_type = null;

      // first disable them all
      let hidden_inputs = document.querySelectorAll('input[data-act-on-inventory-type-price-id]');
      for (let i=0; i < hidden_inputs.length; i++ ) {
        hidden_inputs[i].setAttribute('disabled', true);
      }

      // enable the selected on
      let to_show = document.querySelectorAll('input[data-act-on-inventory-type-price-id="'+selected+'"]');
      for (let i=0; i < to_show.length; i++ ) {
        to_show[i].removeAttribute('disabled');
      }

      for (let i = 0; i < this.inventoryPriceTypeTargets.length; i++) {
        inventory_price_type = this.inventoryPriceTypeTargets[i];
        if (
          parseInt(inventory_price_type.getAttribute('data-index')) == parseInt(selected)
        ) {
          inventory_price_type.classList.add('card-select');
        } else {
          inventory_price_type.classList.remove('card-select');
        }
      }
    }

    if (this.singlePricesContainerTargets.length > 0) {
      let fieldset = null;
      for (let i = 0; i < this.singlePricesContainerTargets.length; i++) {
        fieldset = this.singlePricesContainerTargets[i];
        if (
          parseInt(fieldset.getAttribute('data-index')) == parseInt(selected)
        ) {
          fieldset.classList.remove('d-none');
          fieldset.removeAttribute('disabled');
        } else {
          fieldset.classList.add('d-none');
          fieldset.setAttribute('disabled', true);
        }
      }
    }

    if (this.checkboxTargets.length > 0) {
      let checkbox = null;
      for (let i = 0; i < this.checkboxTargets.length; i++) {
        checkbox = this.checkboxTargets[i];
        if (
          parseInt(checkbox.getAttribute('data-index')) == parseInt(selected)
        ) {
          checkbox.checked = true;
        } else {
          checkbox.checked = false;
        }
      }
    }

    if (this.extraFieldTargets.length > 0) {
      let fieldset = null;
      for (let i = 0; i < this.extraFieldTargets.length; i++) {
        fieldset = this.extraFieldTargets[i];

        // if data-id is found in the array, check it!
        if (
          selectedSinglePrices.indexOf(
            parseInt(fieldset.getAttribute('data-id'))
          ) > -1
        ) {
          fieldset.classList.remove('d-none');
          fieldset.removeAttribute('disabled');
        } else {
          fieldset.classList.add('d-none');
          fieldset.setAttribute('disabled', true);
        }
      }
    }
  }

  handleChange(e) {
    console.log(e.target);
    let index = e.target.getAttribute('data-index');
    this.data.set('selected', index);
    this.updateView();
  }

  handleSinglePrice(e) {
    let id = parseInt(e.target.getAttribute('data-id'));
    let selectedSinglePrices = this.data.get('selectedSinglePrices');
    if (selectedSinglePrices != null) {
      selectedSinglePrices = JSON.parse(selectedSinglePrices);
    } else {
      selectedSinglePrices = [];
    }

    // add or remove
    if (e.target.checked) {
      selectedSinglePrices.push(id);
    } else {
      // if found, remove
      let index = selectedSinglePrices.indexOf(id);
      if (index > -1) {
        selectedSinglePrices.splice(index, 1);
      }
    }

    this.data.set('selectedSinglePrices', JSON.stringify(selectedSinglePrices));
    this.updateView();
  }
}
