import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['daypart'];

  connect() {}

  toggle(e) {
    for (let i = 0; i < this.daypartTargets.length; i++) {
      this.daypartTargets[i].checked = e.target.checked;
    }
  }

  disconnect() {}
}
