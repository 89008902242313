import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  AvailableProductPropType,
  TranslationsProptype,
  UsedProductsProptype,
} from './shared-types';
import ArrangementProduct from './ArrangementProduct';

const ArrangementProductsTable = (props) => {
  const {
    allProducts,
    initialUsedProducts,
    translations: t,
    roomRentalPrice,
    setRoomRentalPrice,
    vatCategories,
  } = props;
  const products = allProducts.map(({ price, ...product }) => ({
    price: Number(price),
    ...product,
  }));
  const [usedProducts, setUsedProducts] = useState(initialUsedProducts);
  const usedProductIds = usedProducts.map((product) => product.id);
  const productsById = Object.fromEntries(
    products.map((product) => [
      product.id,
      { ...product, used: usedProductIds.includes(product.id) },
    ])
  );
  const productsByCategory = {};
  allProducts.forEach((product) => {
    productsByCategory[product.category] ||= [];
    productsByCategory[product.category].push(productsById[product.id]);
  });
  const firstUnusedProductByCategory = Object.fromEntries(
    Object.entries(productsByCategory).map(([category, products]) => [
      category,
      products.find((product) => !product.used),
    ])
  );
  const setUsedProduct = (index) => (id, price) =>
    setUsedProducts([
      ...usedProducts.slice(0, index),
      { id, price: price ?? productsById[id].price },
      ...usedProducts.slice(index + 1),
    ]);
  const removeUsedProduct = (index) => () =>
    setUsedProducts([
      ...usedProducts.slice(0, index),
      ...usedProducts.slice(index + 1),
    ]);
  const addUsedProduct = () => {
    const product = Object.values(firstUnusedProductByCategory).find(
      (product) => product
    );
    setUsedProducts([
      ...usedProducts,
      { id: product.id, price: product.price },
    ]);
  };
  const totalPriceExcludingRoom = usedProducts
    .map((product) => product.price ?? productsById[product.id].price)
    .reduce((sum, term) => sum + term, 0);
  const latestSingleProductStartDate = usedProducts
    .map(({ id }) => productsById[id].date_start)
    .filter((date) => date)
    .reduce(
      (max, current) => (max === null || current > max ? current : max),
      null
    );
  const earliestSingleProductEndDate = usedProducts
    .map(({ id }) => productsById[id].date_end)
    .filter((date) => date)
    .reduce(
      (min, current) => (min === null || current < min ? current : min),
      null
    );
  const startDate = document.getElementById('start-date-picker').value;
  const endDate = document.getElementById('end-date-picker').value;
  const totalPriceInput = document.getElementById('price');
  const hiddenTotalPriceInput = document.getElementById('total-price');
  const totalPrice = totalPriceExcludingRoom + (roomRentalPrice ?? 0);
  // Ternary is a hack to disable submit button when no products or bad dates are selected,
  // because -1 is never expected to match the total price filled in by the user.
  const validationErrors = [];
  if (usedProducts.length === 0)
    validationErrors.push(t.validationMessages.no_components);
  if (totalPrice !== parseInt(totalPriceInput.value))
    validationErrors.push(t.validationMessages.total_price);
  const anyProductSpecificErrors =
    (latestSingleProductStartDate &&
      (!startDate || startDate < latestSingleProductStartDate)) ||
    (earliestSingleProductEndDate &&
      (!endDate || endDate > earliestSingleProductEndDate));

  const mayBeValid = validationErrors.length === 0 && !anyProductSpecificErrors;
  hiddenTotalPriceInput.value = mayBeValid ? totalPrice : -1;
  hiddenTotalPriceInput.dispatchEvent(new Event('change'));

  return (
    <table className="line-table">
      <thead>
        <tr>
          <th>{t.category}</th>
          <th style={{ minWidth: 200 }}>{t.singlePrice}</th>
          <th>{t.priceInArrangement}</th>
          <th>{t.taxRatePercentage}</th>
        </tr>
      </thead>
      <tbody>
        {roomRentalPrice !== null && (
          <tr>
            <td></td>
            <td>{t.roomRentalIncluded}</td>
            <td>
              <input
                type="number"
                value={roomRentalPrice}
                onChange={(e) => setRoomRentalPrice(Number(e.target.value))}
                className="form-control"
              />
            </td>
            <td>21 {/* Room rental tax percentage hard-coded for now */}</td>
          </tr>
        )}
        {usedProducts.map((product, index) => (
          <ArrangementProduct
            key={product.id}
            id={product.id}
            category={productsById[product.id].category}
            price={product.price ?? productsById[product.id].price}
            startDate={productsById[product.id].date_start}
            endDate={productsById[product.id].date_end}
            arrangementStartDate={startDate}
            arrangementEndDate={endDate}
            productsInCategory={
              productsByCategory[productsById[product.id].category]
            }
            firstUnusedProductByCategory={firstUnusedProductByCategory}
            setProduct={setUsedProduct(index)}
            removeProduct={removeUsedProduct(index)}
            t={t}
            vatPercentage={
              vatCategories[String(productsById[product.id].vat_category_id)]
            }
          />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="2"></td>
          <td style={{ paddingTop: 10 }}>
            <span style={{ color: '#9f9f9f' }}>{t.total}</span> {t.currencyUnit}{' '}
            {totalPrice.toFixed(2)}
          </td>
        </tr>
        {!mayBeValid && (
          <tr>
            <td colSpan="5">
              {validationErrors.map((error) => (
                <p key={error}>
                  <small className="form-text" style={{ color: 'red' }}>
                    {error}
                  </small>
                </p>
              ))}
            </td>
          </tr>
        )}
        <tr>
          <td colSpan="3">
            {Object.values(firstUnusedProductByCategory).some(
              (product) => product
            ) && (
              <button
                type="button"
                className="big darkgrey"
                onClick={addUsedProduct}
              >
                <span className="spaced">+</span>
                <span className="spaced">{t.addSinglePrice}</span>
              </button>
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

ArrangementProductsTable.propTypes = {
  allProducts: PropTypes.arrayOf(AvailableProductPropType).isRequired,
  initialUsedProducts: UsedProductsProptype.isRequired,
  translations: TranslationsProptype.isRequired,
  roomRentalPrice: PropTypes.number,
  setRoomRentalPrice: PropTypes.func,
  vatCategories: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
};

export default ArrangementProductsTable;
